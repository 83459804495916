import React, { useState } from "react";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import ProfilePhoto from "./ProfilePhoto";
import NameInfo from "./NameInfo";
import EmailInfo from "./EmailInfo";
import PasswordInfo from "./PasswordInfo";
import UsernameInfo from "./UsernameInfo";
import ProviderConnect from "./ProviderConnect";
import LinkCredentials from "./LinkCredentials";
import { AccountBodyWrapper } from "../AccountBodyWrapper";

const Settings = ({ providerData }) => {
  const [showLinkCredentials, toggleLinkCredentials] = useState(false);

  return (
    <AccountBodyWrapper>
      <Flex flexDirection="column">
        <Helmet title="Settings | Online Dance Classes and Tutorials" />
        <Flex alignItems="center" flexDirection="column">
          <Flex flexDirection="column" width={{ _: "95%", md: "60%" }}>
            {!showLinkCredentials && (
              <>
                <ProfilePhoto providerData={providerData} />
                <Flex
                  flexDirection="column"
                  textAlign="center"
                  mb={4}
                  mt={4}
                  pt={3}
                >
                  <H2>Account Settings</H2>
                </Flex>
                <UsernameInfo />
                <NameInfo />
                <EmailInfo
                  providerData={providerData}
                  toggleLinkCredentials={toggle =>
                    toggleLinkCredentials(toggle)
                  }
                />
                <PasswordInfo />
                <ProviderConnect providerData={providerData} />
              </>
            )}
            {showLinkCredentials && (
              <LinkCredentials
                toggleLinkCredentials={toggle => toggleLinkCredentials(toggle)}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </AccountBodyWrapper>
  );
};

const mapStateToProps = ({ auth, user }) => ({
  auth,
  userPublic: user.public,
  userPrivate: user.private,
  providerData: auth.providerData,
});

Settings.defaultProps = {
  providerData: null,
};

Settings.propTypes = {
  providerData: PropTypes.instanceOf(Array),
  userPublic: PropTypes.shape({}).isRequired,
  userPrivate: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps)(Settings);
