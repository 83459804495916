import { MutationResult, MutationTuple } from "@apollo/client";
import { SET_USERNAME } from "graphql/mutations";
import { useResettableMutation } from "../useResettableMutation";

export default function useSetUsername<TData = any, TVariables = any>(
  options: any
): [
  MutationTuple<TData, TVariables>[0],
  MutationResult<TData> & { reset: () => void }
] {
  const [setUsername, setUsernameResults] = useResettableMutation(
    SET_USERNAME,
    options
  );

  return [
    (opts: any) => {
      const { newUsername } = opts.variables;

      return setUsername({
        variables: {
          input: {
            newUsername,
          },
        },
      });
    },
    setUsernameResults,
  ];
}
