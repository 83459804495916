import React from "react";
import InputAddon from "app/components/Input/InputAddon";
import { User } from "app/components/Icon";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { saveUserPublic } from "modules/user/actions";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Alert from "app/components/Alert";
import ButtonWithLoader from "./ButtonWithLoader";
import { InputWrapper, Label, FlexLeft, SectionWrapper } from "./styles";

class NameInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      error: null,
      success: null,
    };

    this.save = this.save.bind(this);
  }

  save(values) {
    const { savePublic } = this.props;

    this.setState({ saving: true }, () => {
      savePublic(values)
        .then(() => {
          this.setState({
            saving: false,
            error: null,
            success: { message: "Successfully updated name." },
          });
        })
        .catch(error => this.setState({ error, success: null, saving: false }));
    });
  }

  render() {
    const { userPublic } = this.props;
    const { error, success, saving } = this.state;

    return (
      <SectionWrapper>
        <Formik
          enableReinitialize
          initialValues={{
            first_name: userPublic.first_name || "",
            last_name: userPublic.last_name || "",
          }}
          onSubmit={this.save}
          render={({ touched, errors, handleChange, isValid }) => (
            <Form>
              <Flex justifyContent="space-between" width="100%">
                <InputWrapper>
                  <Label>First Name</Label>
                  <InputAddon
                    icon={User}
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>Last Name</Label>
                  <InputAddon
                    icon={User}
                    id="last_name"
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </InputWrapper>
              </Flex>
              {error && (
                <Alert
                  variant="danger"
                  closeAlert={() => this.setState({ error: null })}
                >
                  {error.message}
                </Alert>
              )}
              {success && (
                <Alert
                  variant="success"
                  closeAlert={() => this.setState({ success: null })}
                >
                  {success.message}
                </Alert>
              )}
              <FlexLeft>
                <ButtonWithLoader
                  width="211px"
                  height="32px"
                  disabled={!isValid}
                  loading={saving}
                  type="submit"
                >
                  Update Name
                </ButtonWithLoader>
              </FlexLeft>
            </Form>
          )}
        />
      </SectionWrapper>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  userPublic: user.public,
});

const mapDispatchToProps = {
  savePublic: saveUserPublic,
};

NameInfo.defaultProps = {};

NameInfo.propTypes = {
  userPublic: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  savePublic: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NameInfo);
