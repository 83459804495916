import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  connectProviderAction,
  disconnectProviderAction,
} from "modules/auth/actions";
import PropTypes from "prop-types";
import FacebookLoginButton from "app/components/Button/LoginButtons/FacebookLoginButton";
import Alert from "app/components/Alert";
import Button from "app/components/Button";
import GoogleLoginButton from "app/components/Button/LoginButtons/GoogleLoginButton";
import AppleLoginButton from "app/components/Button/LoginButtons/AppleLoginButton";
import Avatar from "app/components/Avatar";
import { SectionWrapper } from "./styles";

const P = styled.p`
  margin: 10px 0;
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
`;

const StyledButton = styled(Button)`
  padding: 4px;
  font-size: 12px;
`;

class FacebookConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  unlinkProvider = provider => {
    const { unlinkProvider } = this.props;

    unlinkProvider(provider)
      .then(() => {
        this.setState({
          error: null,
        });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  linkProvider = provider => {
    const { linkProvider } = this.props;

    linkProvider(provider)
      .then(() => {
        this.setState({
          error: null,
        });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const { providerData } = this.props;
    const { error } = this.state;
    const facebookProviderData = providerData.find(
      data => data.providerId === "facebook.com"
    );
    const googleProviderData = providerData.find(
      data => data.providerId === "google.com"
    );
    const appleProviderData = providerData.find(
      data => data.providerId === "apple.com"
    );

    return (
      <SectionWrapper>
        {facebookProviderData && (
          <div>
            <Avatar
              width="100px"
              alt="Facebook Avatar"
              src={`${facebookProviderData.photoURL}?height=200&width=200`}
            />
            <P>
              Connected as {facebookProviderData.displayName || "Facebook User"}
            </P>
            {providerData.length > 1 && (
              <StyledButton
                mb={2}
                variant="danger"
                onClick={() => this.unlinkProvider("facebook")}
              >
                Unlink Facebook Account
              </StyledButton>
            )}
          </div>
        )}
        {googleProviderData && (
          <div>
            <Avatar
              width="100px"
              alt="Google Avatar"
              src={`${googleProviderData.photoURL}?height=200&width=200`}
            />
            <P>
              Connected as {googleProviderData.displayName || "Google User"}
            </P>
            {providerData.length > 1 && (
              <StyledButton
                mb={2}
                variant="danger"
                onClick={() => this.unlinkProvider("google")}
              >
                Unlink Google Account
              </StyledButton>
            )}
          </div>
        )}
        {appleProviderData && (
          <div>
            <Avatar
              width="100px"
              alt="Google Avatar"
              src={`${appleProviderData.photoURL}?height=200&width=200`}
            />
            <P>Connected with Apple account</P>
            {providerData.length > 1 && (
              <StyledButton
                mb={2}
                variant="danger"
                onClick={() => this.unlinkProvider("apple")}
              >
                Unlink Apple Account
              </StyledButton>
            )}
          </div>
        )}
        <div>
          {!facebookProviderData && (
            <FacebookLoginButton
              width="100%"
              mb={2}
              message="Connect with Facebook"
              onClick={() => this.linkProvider("facebook")}
            />
          )}
          {!googleProviderData && (
            <GoogleLoginButton
              width="100%"
              mb={2}
              message="Connect with Google"
              onClick={() => this.linkProvider("google")}
            />
          )}
          {!appleProviderData && (
            <AppleLoginButton
              width="100%"
              mb={2}
              message="Connect with Apple"
              onClick={() => this.linkProvider("apple")}
            />
          )}
        </div>
        {error && (
          <Alert
            variant="danger"
            closeAlert={() => this.setState({ error: null })}
          >
            {error.message}
          </Alert>
        )}
      </SectionWrapper>
    );
  }
}

const mapDispatchToProps = {
  unlinkProvider: disconnectProviderAction,
  linkProvider: connectProviderAction,
};

FacebookConnect.defaultProps = {
  providerData: [],
};

FacebookConnect.propTypes = {
  providerData: PropTypes.instanceOf(Array),
  linkProvider: PropTypes.func.isRequired,
  unlinkProvider: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(FacebookConnect);
