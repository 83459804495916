import React from "react";
import Input from "app/components/Input";
import { Formik, Form } from "formik";
import { useQuery } from "@apollo/client";
import Alert from "app/components/Alert";
import { P1 } from "app/components/Typography";
import { PROFILE_QUERY } from "graphql/queries";
import useSetUsername from "hooks/Challenges/useSetUsername";
import ButtonWithLoader from "./ButtonWithLoader";
import { InputWrapper, Label, FlexLeft, SectionWrapper } from "./styles";

const UsernameInfo = () => {
  const {
    loading: profileLoading,
    data: { user: profileData } = {},
  } = useQuery(PROFILE_QUERY);

  const [
    setUsername,
    { data, loading: setUsernameLoading, error, reset },
  ] = useSetUsername({
    onError: (err: Error) => {
      if (
        err.message === "This username is taken." ||
        err.message === "Inappropriate username."
      ) {
        return;
      }
      throw err;
    },
  });

  const loading = setUsernameLoading || profileLoading;

  return (
    <SectionWrapper>
      <Formik
        initialValues={{
          username: profileData?.username || "",
        }}
        enableReinitialize
        onSubmit={values => {
          reset();
          setUsername({ variables: { newUsername: values.username } });
        }}
        render={({ touched, handleChange, values, isValid }) => (
          <Form>
            <Label>Username</Label>
            <P1 color="monochrome.6" mb={3}>
              Your username will be visible to others. It can only contain
              letters, numbers, and underscores.
            </P1>
            <InputWrapper>
              <Input
                id="username"
                name="username"
                type="text"
                placeholder="Username"
                maxLength="24"
                onChange={handleChange}
                errors={error && { username: error.message }}
                touched={touched}
                validate={(inputValues: string) => {
                  if (inputValues && inputValues.length < 1) {
                    return "Username cannot be blank.";
                  }

                  return null;
                }}
              />
            </InputWrapper>
            {data && (
              <Alert variant="success" closeAlert={() => reset()}>
                Successfully updated username.
              </Alert>
            )}
            <FlexLeft>
              <ButtonWithLoader
                width="251px"
                height="32px"
                disabled={values.username === profileData?.username || !isValid}
                loading={loading}
                type="submit"
              >
                Update Username
              </ButtonWithLoader>
            </FlexLeft>
          </Form>
        )}
      />
    </SectionWrapper>
  );
};

export default UsernameInfo;
