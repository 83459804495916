import React from "react";
import styled from "styled-components";
import InputEmail from "app/components/Input/InputEmail";
import InputPassword from "app/components/Input/InputPassword";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import {
  linkCredentialsAction,
  reauthenticateWithPopup,
} from "modules/auth/actions";
import PropTypes from "prop-types";
import Button from "app/components/Button";
import Alert from "app/components/Alert";
import Loader from "app/components/Loader";
import { SectionWrapper } from "./styles";

const FlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Label = styled.label`
  display: inline-block;
  margin: 20px 0 5px 0;
  font-weight: 700;
  font-size: 14px;

  :first-child {
    margin: 0 0 5px 0;
  }
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 5px 10px;
  font-weight: 400;
  margin-top: 10px;
  flex: 0 0 170px;
  margin-right: 10px;
`;

const InputWrapper = styled.div`
  margin-bottom: 10px;
`;

class LinkCredentials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linking: false,
      error: null,
      success: null,
    };
  }

  link = values => {
    const { reauthenticate, link, toggleLinkCredentials } = this.props;

    this.setState({ linking: true }, () => {
      link(values)
        .then(() => {
          this.setState({
            error: null,
            success: { message: "Successfully connected email." },
            linking: false,
          });

          toggleLinkCredentials(false);
        })
        .catch(error => {
          if (error.code === "auth/requires-recent-login") {
            reauthenticate();
          }

          this.setState({ error, success: null, linking: false });
        });
    });
  };

  render() {
    const { toggleLinkCredentials, userPrivate } = this.props;
    const { error, linking, success } = this.state;

    return (
      <SectionWrapper>
        <Formik
          initialValues={{
            email: userPrivate.email,
            password: "",
            confirmPassword: "",
          }}
          onSubmit={this.link}
          validate={values => {
            if (
              values.password !== values.confirmPassword &&
              values.confirmPassword.length >= 6
            ) {
              return { confirmPassword: "Password does not match." };
            }

            return {};
          }}
          render={({ touched, errors, handleChange, values }) => (
            <Form>
              <InputWrapper>
                <Label>Email*</Label>
                <InputEmail
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Current Password</Label>
                <InputPassword
                  name="password"
                  id="password"
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  placeholder="Enter your new password"
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label>New Password</Label>
                <InputPassword
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  placeholder="Confirm your new password"
                  required
                />
              </InputWrapper>
              {error && (
                <Alert
                  variant="danger"
                  closeAlert={() => this.setState({ error: null })}
                >
                  {error.message}
                </Alert>
              )}
              {success && (
                <Alert
                  variant="success"
                  closeAlert={() => this.setState({ success: null })}
                >
                  {success.message}
                </Alert>
              )}
              <FlexRight>
                <StyledButton
                  variant="secondary"
                  onClick={() => toggleLinkCredentials(true)}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  disabled={
                    linking ||
                    !values.email ||
                    !values.password ||
                    !values.confirmPassword ||
                    (errors &&
                      (errors.email ||
                        errors.password ||
                        errors.confirmPassword))
                  }
                  type="submit"
                >
                  {!linking && "Create Login"}
                  {linking && <Loader light width={24} />}
                </StyledButton>
              </FlexRight>
            </Form>
          )}
        />
      </SectionWrapper>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  userPrivate: user.private,
});

const mapDispatchToProps = {
  link: linkCredentialsAction,
  reauthenticate: reauthenticateWithPopup,
};

LinkCredentials.propTypes = {
  link: PropTypes.func.isRequired,
  reauthenticate: PropTypes.func.isRequired,
  toggleLinkCredentials: PropTypes.func.isRequired,
  userPrivate: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkCredentials);
