import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  MutationTuple,
  useMutation,
} from "@apollo/client";
import { useState } from "react";
import { DocumentNode } from "graphql";
import { MutationHookOptions } from "@apollo/client/react/types/types";

/*
 * Pulled directly from https://github.com/marc-ed-raffalli/apollo-hooks-extended/blob/master/src/use-resettable-mutation.ts
 * Needed Typescript refactor for use in .ts/tsx files.
 */

type IResettableMutationState<TData = any> = Pick<
  MutationResult<TData>,
  "data" | "called" | "loading" | "error"
>;

export function useResettableMutation<TData = any, TVariables = any>(
  query: DocumentNode,
  options: MutationHookOptions<TData, TVariables> = {}
): [
  MutationTuple<TData, TVariables>[0],
  MutationResult<TData> & { reset: () => void }
] {
  const [{ loading, data, error, called }, setState] = useState<
    IResettableMutationState
  >({
    called: false,
    loading: false,
  });
  const reset = () => {
    setState({
      data: undefined,
      loading: false,
      called: false,
      error: undefined,
    });
  };

  const [mutate, { client }] = useMutation<TData, TVariables>(query, {
    ...options,
    onCompleted: undefined,
    onError: undefined,
  });
  const mutateWrapper = async (
    opts?: MutationFunctionOptions<TData, TVariables>
  ): Promise<FetchResult<TData>> => {
    try {
      setState({
        data: undefined,
        loading: true,
        called: true,
        error: undefined,
      });

      const response = await mutate(opts);

      setState({
        data: response.data,
        loading: false,
        called: true,
        error: undefined,
      });

      if (options.onCompleted) {
        options.onCompleted(response.data as TData);
      }

      return response;
      // eslint-disable-next-line prettier/prettier
    } catch (err: any) {
      setState({ data: undefined, loading: false, called: true, error: err });

      if (options.onError) {
        options.onError(err);
      }

      throw err;
    }
  };

  return [mutateWrapper, { data, loading, error, called, client, reset }];
}
