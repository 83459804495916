import React from "react";
import InputPassword from "app/components/Input/InputPassword";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { saveUserPassword } from "modules/user/actions";
import PropTypes from "prop-types";
import LoginModule from "app/components/LoginModule";
import Alert from "app/components/Alert";
import ButtonWithLoader from "./ButtonWithLoader";
import PasswordModal from "./PasswordModal";
import { InputWrapper, Label, FlexLeft, SectionWrapper } from "./styles";

class PasswordInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      error: null,
      success: null,
    };

    this.save = this.save.bind(this);
  }

  save(values) {
    const { savePassword } = this.props;

    this.setState({ saving: true }, () => {
      savePassword(values)
        .then(() => {
          this.setState({
            error: null,
            success: { message: "Successfully updated password." },
            saving: false,
          });
        })
        .catch(error => {
          this.setState({ error, success: null, saving: false });
        });
    });
  }

  render() {
    const { error, saving, success } = this.state;

    return (
      <SectionWrapper>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={this.save}
          validate={values => {
            if (
              values.newPassword !== values.confirmPassword &&
              values.confirmPassword.length >= 6
            ) {
              return { confirmPassword: "Password does not match." };
            }

            return {};
          }}
          render={({ touched, errors, handleChange, isValid }) => (
            <Form>
              <InputWrapper>
                <Label>Current Password</Label>
                <InputPassword
                  name="currentPassword"
                  id="currentPassword"
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  placeholder="Enter your current password"
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label>New Password</Label>
                <InputPassword
                  name="newPassword"
                  id="newPassword"
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  placeholder="Enter your new password"
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Confirm Password</Label>
                <InputPassword
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  placeholder="Confirm your new password"
                  required
                />
              </InputWrapper>
              {error && error.code !== "auth/requires-recent-login" && (
                <Alert
                  variant="danger"
                  closeAlert={() => this.setState({ error: null })}
                >
                  {error.message}
                </Alert>
              )}
              {success && (
                <Alert
                  variant="success"
                  closeAlert={() => this.setState({ success: null })}
                >
                  {success.message}
                </Alert>
              )}
              <FlexLeft>
                <ButtonWithLoader
                  width="251px"
                  height="32px"
                  disabled={!isValid}
                  loading={saving}
                  type="submit"
                >
                  Update Password
                </ButtonWithLoader>
              </FlexLeft>
            </Form>
          )}
        />
        <PasswordModal
          isOpen={error && error.code === "auth/requires-recent-login"}
          closeModal={() => this.setState({ error: null })}
        >
          <LoginModule />
        </PasswordModal>
      </SectionWrapper>
    );
  }
}

const mapDispatchToProps = {
  savePassword: saveUserPassword,
};

PasswordInfo.defaultProps = {};

PasswordInfo.propTypes = {
  savePassword: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(PasswordInfo);
